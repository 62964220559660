// Packages:
import React from 'react'
import styled from 'styled-components'


// Styles:
const Wrapper = styled.div`
  display: flex;
  justify-content: center;
  width: 100%;
  margin-top: 6em;
`

const Content = styled.div`
  width: 100%;
  max-width: calc(50em - 1.5em);
`

const Section = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: column;
  width: 100%;
  min-height: 30vh;

  @media (max-width: 666px) {
    width: auto;
    padding: 0 1.5em;
  }
`

const Empty = styled(Section)``

const Title = styled.div`
  font-weight: 700;
  font-size: 3em;

  @media (max-width: 325px) {
    font-size: 2em;
  }

  @media (min-width: 325px) and (max-width: 400px) {
    font-size: 2.5em;
  }

  @media (min-width: 400px) and (max-width: 666px) {
    font-size: 3em;
  }
`

const SubTitle = styled.div`
  margin-top: 0.75em;
  font-weight: 400;
  font-size: 1em;

  @media (max-width: 325px) {
    font-size: 0.75em;
  }
`


// Functions:
const Blog = () => {
  return (
    <Wrapper>
      <Content>
        <Empty>
          <Title>These are not the droids you are looking for.. yet.</Title>
          <SubTitle>I haven't written any blogs till now. Maybe check back in later?</SubTitle>
        </Empty>
      </Content>
    </Wrapper>
  )
}


// Exports:
export default Blog